import React from 'react';
import css from './CustomFilter.module.css';



const CustomFilter = props => {
  const { intl, handleFilter, queryParams } = props;
  const topBarFilterArray = [
    {
      key: "all",
      labelTranslationKey: 'CustomFilter.all',
    },
    {
      key: 'find_expert',
      labelTranslationKey: 'CustomFilter.find_expert',
    },
    {
      key: 'national_mortgage_loan_program',
      labelTranslationKey: 'CustomFilter.mortgage_loan_program',
    },
    {
      key: 'downpayment_assistance',
      labelTranslationKey: 'CustomFilter.downpayment_assistance',
    },
  ];
  const { pub_categoryLevel1 } = queryParams || {};

  return (
    <div className={css.arrayContent}>
      {
        topBarFilterArray.map((arr) => {
          const selectedClass = pub_categoryLevel1 && pub_categoryLevel1 === arr.key;
          const chooseClass = selectedClass ? css.selectedArrayButton : !pub_categoryLevel1 && arr.key === 'all' ? css.selectedArrayButton : css.arrayButton;
          return (<div key={arr.key} className={chooseClass} onClick={(event) => {
            handleFilter(arr.key)
          }}>
            {intl.formatMessage({ id: arr.labelTranslationKey })}
          </div>);
        })
      }
    </div>
  );
};

export default CustomFilter;
